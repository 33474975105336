<template>
  <div>
    <SiteNav @handleGet="handleGet" />

    <main class="relative">
      <div class="gradient-bg privacy-policy-gradient"></div>
      <header class="pt-16 pb-12 lg:pt-28 lg:pb-14">
        <div class="l-container">
          <h1 class="heading level-2">Terms of Use</h1>
        </div>
      </header>

      <section class="mb-20">
        <div class="l-container">
          <Document title="Product WorknProsper" numbered>
            <DocumentSection heading="Terms & Conditions">
              <p>
                PLEASE READ THESE TERMS AND CONDITIONS (&quot;TERMS&quot;)
                CAREFULLY. THESE TERMS FORM A LEGAL AGREEMENT BETWEEN
                WORKNPROSPER, OWNED BY EAZIPAY AND YOU. BY CLICKING ON &quot;I
                ACCEPT&quot; OR &quot;I AGREE&quot; OR BY DOWNLOADING,
                INSTALLING OR OTHERWISE USING THE SERVICES, YOU ACCEPT AND AGREE
                TO THE TERMS CONTAINED HEREIN. IF YOU DO NOT AGREE TO THESE
                TERMS, DO NOT CLICK &quot;I ACCEPT&quot; OR &quot;I AGREE&quot;
                OR DOWNLOAD, INSTALL OR USE THE SERVICES.
              </p>
            </DocumentSection>

            <DocumentSection heading="General Provisions">
              <p>
                These Terms and Conditions contain and constitute the entire
                understanding and agreement between us in connection with and
                about the subject matter of these Terms and Conditions and
                supersede all earlier and other agreements and understandings
                between WorknProsper (&quot;WorknProsper,&quot; “MyEazipay,”
                &quot;Company,&quot; &quot;We&quot; or &quot;Us&quot;), us and
                all earlier representations by either of us about such subject
                matter. Any prior representations, warranties, statements, and
                assurances not expressly set out in these Terms and Conditions
                will have no effect. Each party warrants that there is no
                representation, warranty, promise, term, condition, obligation
                or statement upon which they have relied in entering into these
                Terms and Conditions that are not expressly set out, and no such
                representation, warranty, promise, obligation, statement or any
                other term or condition is to be implied in them whether by any
                usage or course of dealing or otherwise (including by statute or
                common law) except as expressly set out in these Terms and
                Conditions.
              </p>
              <p>
                If there is a lack of clarity on the interpretation of one or
                multiple provisions of these general Terms and Conditions,
                interpretation must adhere to ‘the spirit’ of these provisions.
              </p>
              <p>
                The Company reserves the right to reject your use of the
                Platform or registration without having to state reasons or to
                make an agreement conditional on certain other requirements
                being met. If the Company rejects your use of the Platform or
                registration, the Company will notify you within 10 working days
                after receiving the registration.
              </p>
            </DocumentSection>

            <DocumentSection heading="Definitions">
              <ul>
                <li>
                  <span class="font-semibold">
                    &quot;Company,&quot; “We,” “Us,”
                  </span>
                  or
                  <span class="font-semibold"> “WorknProsper” </span>

                  shall mean MYEAZIPAY TECHNOLOGY SERVICES LIMITED
                  (“MyEazipay”), a company incorporated under the Laws of the
                  Federal Republic of Nigeria with its registered office in
                  Lagos, Nigeria.
                </li>
                <li>
                  <span class="font-semibold"> &quot;Customer(s)&quot; </span>
                  or
                  <span class="font-semibold"> &quot;You&quot; </span>

                  shall mean any person who accesses, downloads, uses, views the
                  Platform and the Services rendered on it.
                </li>
                <li>
                  <span class="font-semibold"> &quot;Online Stores&quot; </span>
                  shall mean the Windows Store, Android Google Play, iOS App
                  Store or any other online store or portal where the Platform
                  will be made available by the Company to the Customer, from
                  time to time.
                </li>
                <li>
                  <span class="font-semibold"> &quot;Platform&quot; </span>
                  shall mean the WorknProsper App and the Website collectively.
                </li>
                <li>
                  <span class="font-semibold"> &quot;Services&quot; </span>

                  shall mean the savings and investment services requested or
                  initiated by the customer on the Platform.
                </li>
                <li>
                  <span class="font-semibold"> “Terms” </span>

                  shall mean the terms and conditions of the use of our Platform
                  as set out in this terms of use.
                </li>
                <li>
                  <span class="font-semibold"> ‘’User Account’’ </span>

                  shall mean Customer Account created by the Customer on the
                  Platform granting access to specific features, services, and
                  functionalities offered by the Platform.
                </li>
                <li>
                  <span class="font-semibold"> "User Information" </span>

                  shall mean personal and non-personal data provided by users
                  during the registration and usage of the Platform.
                </li>
                <li>
                  <span class="font-semibold"> “Third Party” </span>

                  shall mean any individual, association, partnership, firm,
                  company, corporation, consultant, subcontractor, or
                  combination thereof, including joint ventures, that is not a
                  party to this Agreement.
                </li>
                <li>
                  <span class="font-semibold"> &quot;Website&quot; </span>

                  shall mean

                  <a
                    href="http://www.eazipay.ng"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-blue-600 underline"
                  >
                    www.eazipay.ng,</a
                  >
                  managed and operated by the Company for the provision of
                  Services.
                </li>
              </ul>
            </DocumentSection>

            <DocumentSection heading="Information About Us">
              <p>
                WorknProsper is a comprehensive financial platform that
                seamlessly integrates savings, loans, and investment
                opportunities to empower your financial journey. It is a
                versatile and friendly experience, allowing customers to
                confidently navigate saving, borrowing, and investing. Our
                digital solutions help individuals manage payments, save money,
                access loans and investments, and automate these transactions.
              </p>
            </DocumentSection>

            <DocumentSection heading="Our Services">
              <p>
                Our Services allow you access a range of financial solutions
                that allow you to make payments, invest, save and get loans. By
                using and continuing to use and/or utilise our Services/the
                Platform, you hereby acknowledge your agreement and
                understanding of the following:
              </p>
              <ul class="alpha">
                <li>
                  <bold class="font-semibold"> Savings service terms: </bold>
                  <p>
                    The WorknProsper savings service allows you to save your
                    money using the WorknProsper platform through different
                    types of savings options and offers interest based on each
                    savings option. The lock period and interest rates of all
                    plans in our Platform shall be as stated in each savings
                    plan (as may be revised or amended by WorknProsper from time
                    to time), subject to Applicable Law and/or current market
                    circumstances.
                  </p>

                  <p>
                    WorknProsper may, with or without any prior notice to the
                    Customer, revise, change or amend the applicable lock
                    periods and/or interest rates of any of the plans on the
                    Platform. You hereby undertake to hold WorknProsper, its
                    directors, officers, employees, agents, successors or
                    assigns harmless, free from and against all claims, losses,
                    damages, liabilities, costs and expenses (including but not
                    limited to reasonable legal fees) that may arise as a result
                    of the provision of this clause.
                  </p>
                  <p>
                    You acknowledge that you understand the requirements and
                    terms of each savings option to be the following:
                  </p>
                  <ul>
                    <li>
                      <span class="font-semibold"> EaziLock: </span>
                      The EaziLock option provides an opportunity to save money
                      through the Eazilock feature. You can lock a specified
                      amount periodically and automate the lock feature,
                      automatically allowing you to lock an amount from time to
                      time. All locked funds attract an interest rate of 5%
                      (five per cent) per month. Your locked funds will be
                      inaccessible to you until the date you have set for
                      withdrawal. Once activated, the Eazilock is unbreakable
                      until the date set for withdrawal.
                    </li>
                    <li>
                      <span class="font-semibold">Emergency Funds: </span>
                      The Emergency Fund option allows you to save funds for
                      emergencies or unforeseen circumstances. The Emergency
                      Fund option provides the flexibility to make up to 3
                      (three) monthly withdrawals, each spaced twelve (12) hours
                      apart.
                    </li>
                    <li>
                      <span class="font-semibold">Target Savings: </span>
                      The Target Savings feature empowers you to save
                      purposefully to meet a financial milestone within a
                      certain time frame. The Target feature allows you to earn
                      interest on your savings when you meet your financial
                      target. However, in the instance where you fail to meet
                      your set financial target, you forfeit the interests on
                      the savings.
                    </li>
                  </ul>
                </li>
                <li>
                  <bold class="font-semibold"> Loan access and recovery: </bold>
                  <p>
                    Our Platform allows you to access loans. To be eligible for
                    a loan,
                  </p>
                  <ul>
                    <li>
                      you must be an employee of a company registered with
                      WorknProsper;
                    </li>
                    <li>
                      your salary account must be linked to this Platform;
                    </li>
                    <li>
                      have supplied all necessary details required for setting
                      up your account (such as bank account number, bank
                      verification number, and other details that may be
                      requested);
                    </li>
                    <li>must not have an outstanding loan;</li>
                    <li>
                      Must not have defaulted on the payment of previous loans.
                    </li>
                  </ul>
                  <p>
                    All loans on the Platform attract an interest rate of 5% per
                    month. The specific loan amount you qualify for is
                    determined by our system evaluating your scoring rates and
                    credit history. The approval of the requested loan amount is
                    not guaranteed if it does not meet our highest-level
                    requirements. However, loans may attract default interest
                    fee at an additional rate of 4% for every month You default
                    on the repayment of your loan. In extreme cases of default,
                    the Company reserves the right to share your data with
                    third-party loan recovery platforms for loan recovery. We
                    may also reach out to your employer to lodge a loan default
                    complaint. Perpetual defaulters will be prevented from
                    further accessing the loan feature on the Platform.
                  </p>
                  <p>
                    You agree to repay the loan amount (including both the
                    principal and accrued interest) in accordance with the terms
                    and conditions and in line with your repayment schedule to
                    your WorknProsper Wallet/Account provided to You.
                  </p>
                </li>
                <li>
                  <bold class="font-semibold">
                    Redeeming money and transfers:
                  </bold>
                  <p>
                    Through the Platform, you may require that funds held in
                    your Savings Account be transferred back to your Nominated
                    Account at any time. This process may take up to 24 hours
                    following a withdrawal request. If you do not have
                    sufficient available funds in your Savings Account to cover
                    the amount of the requested transfer, your request for the
                    transfer will be declined.
                  </p>
                  <p>
                    You may instruct that funds held in one of your Savings
                    Accounts be transferred to your Investment Account. This
                    process may take up to five working days following a
                    transfer request.
                  </p>
                  <p>
                    Please note that you will only be able to receive dividend
                    payouts associated with your Investment Account if you have
                    an active, open, account with WorknProspa. If you close your
                    account, you will not be able to receive dividend payments
                    and We reserves the right to retain these funds and donate
                    them to a charity.
                  </p>
                </li>
                <li>
                  <bold class="font-semibold"> Investment: </bold>
                  <p>
                    Our Platform allows you to invest in assets of third-party
                    companies, such as real estate. The return on investment is
                    relative to the asset that you invest in. While We
                    facilitate the investment process, we do not guarantee an
                    assured return on investment or recovery of the capital
                    invested. You accept that WorknProsper have not made any
                    guarantees as to the security of your investments.
                  </p>

                  <p>
                    You will have a purview of listed Investment opportunities
                    with investment specifications and information.
                    WorknProsper’s role is limited to providing the Services for
                    the publication of information on the listed Investment
                    opportunities.
                  </p>
                  <p>
                    WorknProsper is not responsible for errors in information
                    about the Investment opportunities on the Platform i.e the
                    information does not reflect the state of the Investment
                    Asset upon physical inspection by the Customer. You accept
                    that the information contained in this Website is provided
                    “as is, where is”, and is intended for informational
                    purposes only, and is subject to change without notice.
                    Although we take reasonable steps to ensure the accuracy of
                    the information and we believe the information to be
                    reliable when posted, it should not be relied upon, and it
                    does not in any way constitute either a representation or a
                    warranty, or a guarantee.
                  </p>
                  <p>
                    Whilst WorknProsper facilitates electronic or other
                    communications with the Customers, it accepts no liability
                    regarding the availability or performance of any such
                    Platform.
                  </p>
                  <p>
                    Where we offer features such as goals or target dates for
                    any products, these are for information purposes to help you
                    manage your finances. We are not promising that the Service
                    will enable you to meet your goal or your desired target
                    date.
                  </p>
                  <p>
                    We may add, amend, suspend or remove products or services
                    from time to time.
                  </p>
                </li>
              </ul>
              <p class="font-semibold">Additional Service</p>
              <p>
                You understand and acknowledge that by accepting these Terms and
                Conditions, the Company reserves the right to introduce you to a
                Third Party to provide additional Services that may expand your
                obligations under these terms and conditions.
              </p>
            </DocumentSection>

            <DocumentSection heading="Registration">
              <ul class="alpha">
                <li>
                  <p class="font-semibold">Account Creation</p>
                  <p>
                    To use our Services, you must first register for an Account
                    on the WorknProsper Platform. To create an Account, you will
                    provide us with certain information that personally
                    identifies you, such as your email, first name, last name,
                    phone number, residential address, bank details and card
                    details.
                  </p>
                  <p>
                    You agree that the information you provide to WorknProsper
                    on registration and at all other times will be true,
                    accurate, current and complete and that you will keep this
                    information accurate and up-to-date at all times.
                  </p>
                  <p>
                    In the case of a company, by registering for a WorknProsper
                    Account, you warrant that you have the authority to bind the
                    company (as the contracting party) to these Terms of
                    Service. In the case of an individual, by registering for a
                    WorknProsperAccount, you warrant that you are:
                  </p>
                  <ul class="roman">
                    <li>18 years of age or older;</li>
                    <li>
                      You are not a criminal or have been convicted for
                      committing a crime;
                    </li>
                  </ul>
                  <p>
                    You hereby represent and warrant that the information you
                    provide to WorknProsper upon registration and at all other
                    times will be true, accurate, current, and complete. You
                    will ensure that this information is kept up-to-date at all
                    times.
                  </p>
                  <p>
                    You are responsible for any use of our Services with your
                    WorknProsperAccount details and password and for protecting
                    your WorknProsperAccount details and password from
                    unauthorised use. You must not share this information with
                    any other person. You are also responsible for the security
                    of any computer on which you choose to sign in to your
                    WorknProsper Account. You warrant that if you are acting on
                    behalf of your company in dealing with WorknProsper, you
                    have the capacity to act in such a manner and capacity.
                  </p>
                  <p>
                    We collect, verify, and store your User Information to
                    facilitate these transactions. You authorise us to import
                    and periodically update your personal information from Third
                    Party Platforms. We handle your User Information in
                    compliance with Nigeria’s Data Protection Framework, and you
                    provide your consent for us to use, access, modify, and
                    store this information.
                  </p>
                  <p>
                    Transactions carried out by WorknProsper on your behalf are
                    strictly based on your instructions. You also authorise
                    Eazipay to take the necessary actions for Service provision,
                    as long as it complies with relevant laws and regulations.
                  </p>
                  <p>
                    Upon verifying and processing your User Information, we may
                    grant you access to our Services, subject to eligibility
                    criteria and Platform-specific Terms and Conditions. You are
                    solely responsible for all activities under your User
                    Account and agree that the Company is not liable for any
                    claims or damages arising from the use or misuse of the
                    Services. You must maintain the confidentiality of your User
                    Account.
                  </p>
                  <p>
                    You are responsible for the capability of your electronic
                    devices and internet connection for Platform use, subject to
                    hardware, software, and internet availability.
                  </p>
                  <p>
                    If we have previously deactivated a WorknProsperAccount due
                    to your violation of these Terms of Service (or earlier
                    versions thereof), we reserve the right to refuse to
                    register you or to delete your WorknProsperAccount.
                  </p>
                </li>
                <li>
                  <p class="font-semibold">Identity Verification</p>
                  <p>
                    You hereby authorise WorknProsper, directly or through third
                    parties, to make any necessary inquiries to validate your
                    identity and/or authenticate your identity and Account
                    information. We may need additional information or proof to
                    verify your identity, such as requesting government-
                    approved documentation or confirming ownership of your
                    email, phone number, or financial instruments. This may
                    involve checking your details against verifiable,
                    third-party databases or other sources.
                  </p>
                </li>
              </ul>
            </DocumentSection>

            <DocumentSection heading="Use of the Platform">
              <p>To use the Platform, you must:</p>
              <ul class="alpha">
                <li>
                  accept and agree to these Terms and our
                  <a
                    href="https://www.myeazipay.com/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-blue-600 underline"
                    >Privacy Notice</a
                  >;
                </li>
                <li>register with us on the Platform;</li>
                <li>
                  Open a WorknProsper Wallet via the WorknProsper Platform;
                </li>
                <li>
                  have a Bank Account/Wallet with a financial institution; and
                </li>
                <li>
                  Accurately supply all the information we ask for, including
                  your name, email address, mobile device number, online banking
                  credentials, and any other information we may occasionally
                  need
                  <span class="font-semibold">
                    (&quot;User Information&quot;).
                  </span>
                </li>
              </ul>
              <p>
                You represent and warrant that all User Information you provide
                us from time to time is accurate, current, and complete, and you
                agree not to misrepresent your identity or your User
                Information. You agree to promptly notify us of changes to your
                User Information by updating your User account on the Platform.
                If we approve your registration, you will be authorised to use
                the Platform, subject to these Terms.
              </p>
              <p>
                After registration, you will receive login information to access
                the Platform with a verified email address and password.
                Customer registrations are for named Customers and cannot be
                shared with other person (s). We reserve the right to monitor
                your use of the Platform to effect these Terms and/or verify
                compliance with any use limits and/or these Terms.
              </p>
              <p>
                If your use of the Platform compromises WorknProsper&#39;s
                integrity, security, availability, or performance, we may
                modify, restrict, or suspend your access immediately and without
                notice. We expect you to cooperate with us to resolve such
                difficulties as soon as possible.
              </p>
              <p>
                We have the right to suspend your access to the Platform for
                emergency or urgent maintenance. We will use commercially
                reasonable efforts to give you reasonable advance notice of any
                scheduled non-emergency maintenance that may lead to downtime of
                the Platform. Non-emergency maintenance will be conducted
                outside of business hours to the greatest extent possible.
              </p>
            </DocumentSection>

            <DocumentSection heading="Fees">
              <ul class="alpha">
                <li>
                  It is free to create an account and hold money in a Savings
                  Account with WorknProsper, but we may charge subscription fees
                  to access different levels of service.
                </li>
                <li>
                  Platform fees may apply when your money is invested in a fund.
                </li>
                <li>
                  Fees will be calculated and billed over an applicable Service
                  or product and will be debited from your any of your account
                  held with us. If a fee payment fails, we will attempt to
                  charge your card again regularly over a reasonable period.
                </li>
                <li>
                  If you do not hold a balance with us, but then add money to
                  your account, it is possible that we will debit any
                  outstanding fee payments from this deposit(s).
                </li>
                <li>
                  We may need to introduce administration charges for specific
                  purposes. The details will be notified to you in advance.
                </li>
                <li>
                  If we fail to collect the appropriate fee we reserve the right
                  to downgrade your plan or to close your account.
                </li>
              </ul>
            </DocumentSection>

            <DocumentSection heading="Personal Data and Information Collected">
              <p>
                We may collect, use, store and transfer different kinds of
                personal data about You, which we have grouped as follows:
              </p>
              <ul class="alpha">
                <li>
                  Identity Data - First name, maiden name, last name, username
                  or similar identifier, marital status, title, date of birth,
                  photograph/photo ID, National Identification Number (NIN),
                  gender, and employer details.
                </li>
                <li>
                  Contact Data - Residential address, email address and
                  telephone numbers.
                </li>
                <li>
                  Financial Data - Bank verification number (BVN), bank account
                  details, card details, and income details.
                </li>
              </ul>
              <p>
                WorknProsper reserves the right to obtain necessary information
                from relevant third parties regarding Your employment details,
                salary payment history, loans, and other related data. This
                information will be used solely to assess and make a decision on
                Your loan or investment application. WorknProsper further
                reserves the right to request additional information or
                documentation from You or any applicable third party to assess
                Your eligibility for a loan or investment.
              </p>
            </DocumentSection>
            <DocumentSection heading="Confidentiality And Data Security">
              <p>
                You own all your User Information entered and stored by the
                Company via the Platform. You are responsible for the
                information&#39;s reliability and accuracy. We store your User
                Information for your convenience, eliminating the need for
                constant log-ins. The Company will not use the information you
                provide for any other reason.
              </p>
              <p>
                You can delete or uninstall the Platform, but you will still be
                obligated to fulfil Eazipay obligations, including outstanding
                payments. If the Agreement is terminated, your data will be
                inaccessible.
              </p>
              <p>
                To avoid data loss, the company backs up your data every day for
                60 days. We guarantee that data processing regulations are met.
                You guarantee compliance with data processing requirements by
                ensuring that User Information provided by you does not violate
                third-party rights. You undertake to hold the Company harmless
                from any third-party claims relating to this data or the
                agreement.
              </p>
              <p>
                When you subscribe to the Platform, you agree to our processing
                of your User Information. For additional information on personal
                data processing, please see the Privacy Notice.
              </p>
            </DocumentSection>

            <DocumentSection heading="Promotions">
              <p>
                From time to time we may offer some promotions and terms will be
                made available. We reserve the right to withdraw any promotional
                terms at any time.
              </p>
            </DocumentSection>

            <DocumentSection heading="Licence">
              <p>
                You must either download and install the App or use the services
                directly on our website to be granted a licence to use the tools
                and services made available through the Platform. The Company
                grants you a limited, non-exclusive, non-transferable,
                non-sub-licensable and revocable right to use the Platform. The
                Platform is licenced and not sold to you and shall only be used
                as per these Terms and Conditions.
              </p>
              <ul>
                <li>
                  Scope of Licence: You may use the Platform on/from mobile
                  phones, tablets or any other electronic devices.
                </li>
                <li>
                  Maintenance &amp; Support: You acknowledge that while the
                  Company may, at its sole discretion, provide maintenance and
                  support for the Platform from time to time, the Company shall
                  have no specific obligation whatsoever to furnish such
                  services to you.
                </li>
                <li>
                  Updates/Upgrades: We may launch new updates/upgrades for the
                  Platform, you may subscribe to the same through the Online
                  Stores. In the event, you choose not to update/upgrade the
                  Platform, certain features or functionality will not be
                  accessible to you.
                </li>
              </ul>
            </DocumentSection>

            <DocumentSection heading="Restrictions">
              <p>You agree not to :</p>
              <ul>
                <li>
                  Use the Platform for illegal or unlawful purposes, including
                  fraud, embezzlement, or money laundering;
                </li>
                <li>Reproduce, sell, or exploit any part of the Platform;</li>
                <li>
                  Share content that is unlawful, harmful, abusive, defamatory,
                  or objectionable;
                </li>
                <li>Harm or injure third parties through the Platform;</li>
                <li>Impersonate any person or entity on the Platform;</li>
                <li>Manipulate identifiers to disguise content origin;</li>
                <li>
                  Share content you do not have the right to disclose, such as
                  confidential information;
                </li>
                <li>
                  Infringe on intellectual property rights, including patents,
                  trademarks, and copyrights;
                </li>
                <li>
                  Send unsolicited or unauthorised advertising, spam, or
                  solicitations;
                </li>
                <li>
                  Share material containing software viruses or code designed to
                  disrupt or damage computer systems;
                </li>
                <li>
                  Disrupt the normal flow or negatively affect other
                  Customers&#39; real-time exchanges;
                </li>
                <li>
                  Interfere with the Platform or connected networks or disobey
                  network regulations;
                </li>
                <li>
                  Violate any applicable local, state, national, or
                  international laws and regulations;
                </li>
              </ul>
            </DocumentSection>

            <DocumentSection heading="Proprietary Properties Of The Company">
              <p>
                The Company exclusively owns all rights, including intellectual
                property, in the Platform, Services, logos, and software, except
                for Third Party properties.
              </p>
              <p>
                The Platform display of the logos, trademarks, and service marks
                owned by the Company or others does not grant you a licence, and
                unauthorised use breaches intellectual property laws.
              </p>
              <p>
                The Platform belongs to the Company; these Terms do not transfer
                ownership. You can use the Platform and the Services during your
                User Account’s validity.
              </p>
            </DocumentSection>

            <DocumentSection heading="Password and Account Security">
              <p>
                You must not share your password details or allow anyone else to
                access your account. The Company is not responsible for losses
                if you share your password. Take steps to secure your electronic
                device, such as using a PIN or password protection, and avoid
                sharing it with others. Manage your passwords securely and
                inform Eazipay if you notice any unauthorised access to your
                account.
              </p>
            </DocumentSection>
            <DocumentSection heading="Closing accounts">
              <p>
                You can give us notice to close your account from the ‘Settings’
                menu within the App at any time unless:
              </p>
              <ul class="alpha ml-5">
                <li>we are currently processing payments for you;</li>
                <li>You have an outstanding loan;</li>
                <li>
                  the terms of a specific product, service or feature is subject
                  to additional notice requirements, in which case those notice
                  requirements will apply.
                </li>
              </ul>
            </DocumentSection>
            <DocumentSection heading="Links To Third Parties">
              <p>
                The Platform may feature links to third-party Platforms
                <span class="font-medium">
                  (&quot;Linked Platforms&quot;)
                </span>
                not controlled by the Company. The Company is not responsible
                for Linked Platforms content or hyperlinks and does not endorse
                them. Your use of Linked Platforms is at your own risk and
                subject to their terms and conditions, and the Company is not
                involved in any transactions between you and Linked Platforms.
                Third-party adverts may also be displayed on the Platform. This
                is not an endorsement of the advertiser or their products or
                services. The Company accepts no responsibility for contact with
                third parties and is not accountable for any issues that may
                arise as a result.
              </p>
            </DocumentSection>
            <DocumentSection heading="Termination">
              <p>
                We reserves the right to terminate these Terms and Conditions in
                the event:
              </p>
              <ul class="ml-5">
                <li>You breach any provision of these Terms;</li>
                <li>The Company is required to do so under law;</li>
                <li>Liquidation;</li>
                <li>
                  The Company chooses to discontinue the Service being offered
                  or discontinue operating the Platform;
                </li>
                <li>The licence granted to use the Platform expires;</li>
                <li>Of non-payment of outstanding loan sum.</li>
              </ul>
              <p>
                Upon termination of these Terms, your rights and licences under
                these Terms will immediately expire. You must cease using the
                Platform and the Service. Termination of these Terms, for any
                reason, does not relieve you of your obligations, including but
                not limited to repaying any outstanding sum.
              </p>
            </DocumentSection>
            <DocumentSection heading="Indemnity">
              <p>
                You agree to indemnify the Company, its subsidiaries,
                affiliates, officers, agents, partners, and employees against
                third-party claims, including legal fees, arising from: (a) your
                violation of these Terms (b) infringing other Customers&#39;
                rights on the Platform; (c) your use or misuse of the Platform,
                (d) violating applicable laws.
              </p>
              <p>
                The Company will indemnify you against losses or expenses from
                third-party claims related to Platform Intellectual Property
                Rights, except when: (a) the Platform was modified by someone
                other than us; (b) unauthorised software, hardware, networks, or
                systems were used with the Platform, (c) the Platform was used
                contrary to our instructions or Documentation.
              </p>
              <p>
                If an indemnity obligation arises, you shall: (a) promptly
                notify us of the claim, (b) provide reasonable assistance in
                defence and settlement, at our expense, (c) we will control the
                defence and settlement. You cannot settle without our written
                consent if it adversely affects our rights or interests or adds
                obligations.
              </p>
            </DocumentSection>
            <DocumentSection heading="Exclusions">
              <p>
                Some jurisdictions do not allow the exclusion of certain
                warranties or the limitation or exclusion of liability for
                certain damages. Accordingly, some of the above disclaimers and
                limitations of liability may not apply to you. To the extent
                that any Party may not, as a matter of applicable law, disclaim
                any implied warranty or limit its liabilities, the scope and
                duration of such warranty and the extent of WorknProsper’s
                liability shall be the minimum permitted under such applicable
                law.
              </p>
            </DocumentSection>
            <DocumentSection heading="Limitations of Liability">
              <p>
                You expressly understand and agree that the Company, including
                its directors, officers, employees, representatives or the
                service provider, shall not be liable for any direct, indirect,
                incidental, special, consequential or exemplary damages,
                including but not limited to, damages for loss of profits,
                goodwill, use, data or other intangible losses (even if the
                Company has been advised of the possibility of such damages),
                resulting from; (a) use or the inability to avail the Platform
                (b) inability to use the Platform (c) failure or delay in
                accessing the Platform (d) any performance or non-performance by
                the Company (e) any damages to or viruses that may infect your
                electronic devices or other property as the result of your
                access to the Platform or your downloading of any content from
                the Platform and (f) server failure or otherwise or in any way
                relating to the Platform.
              </p>
              <p>
                We are not responsible for consequential loss. This includes
                liability for:
              </p>
              <ul class="alpha">
                <li>
                  costs, fees (including overdraft fees), interest charges or
                  penalties incurred as a result of the Service transferring
                  automated savings from your bank account to your Savings
                  Account held with us; or
                </li>
                <li>
                  the consequences of any change to your credit rating or
                  equivalent which may arise from you failing to meet any
                  payment from your bank account when due because of your use of
                  the Service.
                </li>
                <li>
                  any delays in receiving withdrawn funds which may arise from
                  you depositing into an account by error.
                </li>
                <li>
                  We do not exclude or limit in any way our liability to you
                  where it would be unlawful to do so. This includes liability
                  for death or personal injury caused by our negligence or the
                  negligence of our employees, agents or subcontractors or for
                  fraud or fraudulent misrepresentation.
                </li>
                <li>
                  damage that you could have avoided by following our advice to
                  apply an update offered to you free of charge.
                </li>
                <li>
                  We are not liable for business losses. The Platform is for
                  private use. If you use the Platform for any commercial,
                  business or resale purpose we will have no liability to you
                  for any loss of profit, loss of business, business
                  interruption, or loss of business opportunity.
                </li>
                <li>
                  Limitations to the Platform and the Service. The Platform and
                  the Service are provided for general information purpose only.
                  They do not offer advice on which you should rely. You must
                  obtain professional or specialist advice before taking, or
                  refraining from, any action on the basis of information
                  obtained from the Platform or the Service. Although we make
                  reasonable efforts to update the information provided by the
                  Platform and the Service, we make no representations,
                  warranties or guarantees, whether express or implied, that
                  such information is accurate, complete or up to date.
                </li>
              </ul>
              <p>
                You understand and acknowledge that you shall be solely
                responsible for all the activities that occur under your User
                Account while accessing the Platform.
              </p>
            </DocumentSection>
            <DocumentSection heading="Force Majeure">
              <p>
                We shall not be liable or responsible for any failure to
                perform, or delay in performance of, any of our obligations
                under these Terms that are caused by events outside of our
                reasonable control
                <span class="font-semibold"> (“Force Majeure Event”). </span>
              </p>
              <p>
                A Force Majeure Event includes, in particular (but without
                limitation) the following: strikes, lockouts or other industrial
                action; civil commotion, riot, invasion, terrorist attack or
                threat of terrorist attack; war (whether declared or not) or
                threat or preparation for war; fire, explosion, storm, flood,
                earthquake, subsidence, epidemic, pandemic or other natural
                disaster or Act of God; nuclear, chemical or biological
                contamination or sonic boom; impossibility of the use of public
                or private telecommunications networks; the acts, decrees,
                legislation, regulations or restrictions of any government; the
                non-delivery or late delivery of products or Service to us by
                third parties; or any other event beyond a Party’s reasonable
                control.
              </p>
              <p>
                Our performance under these Terms and Conditions is deemed to be
                suspended for the period that the Force Majeure Event continues,
                and we will have an extension of time for performance for the
                duration of that period.
              </p>
            </DocumentSection>
            <DocumentSection heading="Notice">
              <p>
                All notices given by you to us must be given in writing
                (including by email) to hi@eazipay.ng as may be updated from
                time to time.
              </p>
              <p>
                We may give notice to you at either the e-mail or postal address
                provided to us when registering for a trial period. Notice will
                be deemed received and properly served 24 (twenty-four) hours
                after an email is sent, or five days after the date of posting
                any letter. In proving the service of any notice, it will be
                sufficient to prove, in the case of a letter, that such a letter
                was properly addressed, stamped and placed in the post and, in
                the case of an email, that such email was sent to the specified
                email address of the addressee.
              </p>
            </DocumentSection>
            <DocumentSection heading="Dispute Resolution">
              <p>
                In the event of any dispute arising between Us and You relating
                to or arising out of, this Agreement (a “Dispute”), the Parties
                undertake to settle the Dispute amicably among themselves. If
                any such dispute is not settled between the Parties within
                fifteen (15) days, the Parties agree to submit such dispute to
                Mediation at the Lagos State Multi-Door Courthouse (LMDC) for
                resolution under the provisions of Lagos State Multi-Door
                Courthouse (LMDC) Law 2007.
              </p>
              <p>
                The mediation seat, or legal place, shall be Lagos, Nigeria. The
                language to be used in the mediation proceedings shall be
                English. The award shall be final and binding. Any competent
                court may enter judgement in relation to the mediator&#39;s
                award.
              </p>
              <p>
                Nothing in this clause shall limit or be construed to limit the
                right of either Party to take proceedings against the other
                Party in any court of competent jurisdiction to obtain security
                for its claim or injunctive relief against the other Party.
              </p>
              <p>
                When a dispute arises and is under mediation, except for the
                matter under Dispute, the Parties shall continue to exercise
                their respective rights and fulfil their remaining obligations
                under this Agreement.
              </p>
              <p>
                Notice of mediation and service of any legal proceedings
                permitted by this clause or at law may be validly effected by or
                under cover of any Notice sent in conformity with clause (clause
                for notice) of this Agreement and neither Party shall contest
                service effected by these means.
              </p>
            </DocumentSection>
            <DocumentSection heading="Governing Law/Jurisdiction">
              <p>
                The construction, performance, execution and enforcement of this
                Agreement shall be governed by and construed in accordance with
                the laws of the Federal Republic of Nigeria.
              </p>
            </DocumentSection>
            <DocumentSection heading="Severability">
              <p>
                Where any portion of these Terms of Use is deemed invalid or
                unenforceable either in whole or in part by any court or
                tribunal, such part shall be severed from the Terms of Use. It
                shall not affect the validity or enforceability of any other
                part of this Terms of Use.
              </p>
            </DocumentSection>
            <DocumentSection heading="Updates, Modifications, and Amendments">
              <p>
                As our technology evolves, we may need to update, modify, or
                amend our Terms of Use, tools, utilities, or general updates. We
                reserve the right to make changes to this Terms of Use at any
                time without notice to you.
              </p>
              <p>
                Updates or upgrades may include security or bug fixes,
                performance enhancements, or new functionality and may be issued
                with or without prior notification to the Customer. The Customer
                hereby consents to such automatic updates.
              </p>
              <p>
                Please check this page often, referring to the date of the last
                modification on the page to ensure you are familiar with the
                current version of the Terms of Use. If a Customer objects to
                any of the changes to the Terms of Use, the Customer must cease
                using our Platform immediately.
              </p>
            </DocumentSection>
            <DocumentSection heading="Violations">
              <p>
                Please report any violations or grievances about these Terms and
                Conditions to the Company at
                <a
                  href="mailto:http://www.hi@eazipay.ng"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="text-blue-600 underline"
                >
                  hi@eazipay.ng.</a
                >
              </p>
            </DocumentSection>
            <DocumentSection heading="Site Management">
              <p>
                We reserve the right, but not the obligation, to; (1) monitor
                the Site for violations of these Terms of Use; (2) take
                appropriate legal action against anyone who, in our sole
                discretion, violates the law or these Terms of Use, including
                without limitation, reporting such Customer to law enforcement
                authorities; (3) in our sole discretion and without limitation,
                refuse, restrict access to, limit the availability of, or
                disable (to the extent technologically feasible) any of your
                Contributions or any portion thereof; (4) in our sole discretion
                and without limitation, notice, or liability, to remove from the
                Site or otherwise disable all files and content that are
                excessive in size or are in any way burdensome to our systems;
                and (5) otherwise manage the Site in a manner designed to
                protect our rights and property and to facilitate the proper
                functioning of the Site.
              </p> </DocumentSection
            ><DocumentSection heading="Waiver">
              <p>
                If we fail at any time to insist upon the strict performance of
                any of your obligations under any of these Terms, or if we fail
                to exercise any of the rights or remedies to which we are
                entitled under these Terms, this shall not constitute a waiver
                of such rights or remedies and shall not relieve you from
                compliance with such obligations. A waiver by Us of any default
                shall not constitute a waiver of any subsequent default. No
                waiver by us of any of these Terms shall be effective unless it
                is expressly stated to be a waiver and is communicated to you in
                writing in accordance with these Terms.
              </p>
            </DocumentSection>
          </Document>
        </div>
      </section>
    </main>
    <Modal v-if="isOpen" @close="isOpen = false">
      <div class="">
        <img src="./assets/qrcodeIos.svg" class="w-52 h-52" />

        <p class="text-center text-white">Scan to Download</p>
      </div>
    </Modal>
    <SiteFooter worknprosper />
  </div>
</template>

<script setup>
import SiteNav from "../../components/wnpSiteNav.vue";
import Modal from "./components/Modal.vue";
import SiteFooter from "../../components/SiteFooter.vue";
import DocumentSection from "../../components/documents/DocumentSection.vue";
import Document from "../../components/documents/Document.vue";
import { ref } from "vue";
const isOpen = ref(false);

const handleGet = () => {
  isOpen.value = true;
};
</script>

<style lang="scss" scoped src="../../styles/www.scss"></style>
<style lang="scss" scoped src="../../styles/doc.scss"></style>
<style lang="scss" scoped>
ul.alpha {
  list-style-type: lower-alpha;
}
ul.roman {
  list-style-type: upper-roman;
}
</style>
